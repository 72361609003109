import React from 'react'

import IndexLayout from '../layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <h1>404: Page not found.</h1>
  </IndexLayout>
)

export default NotFoundPage
